import React, { Component, Fragment } from 'react';
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import { rhythm } from "../utils/typography"
import Helmet from 'react-helmet'
import queryString from 'query-string';

import Layout from "../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function handleClick(e) {
  let clickElmStr = e.target.toString();
  if (clickElmStr.indexOf("?rid=") === -1) {
    return;
  }
  if (typeof window !== 'undefined') {
    if (localStorage.getItem("rid") !== null) {
      var labelValue = '';
      var rId = '';
      var deferCampaignLog = new $.Deferred().resolve();
      deferCampaignLog.promise()
        .then(function() {
            rId = localStorage.getItem("rid");
            return rId
        })
        .then(function(rId) {
            if (rId === null) {
                rId = 'organic'
            }
            labelValue = rId+' | '+localStorage.getItem("cookinburger_guid_raw")+'-'+rId+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
            dataLayer.push({
                'event' : 'gaEvent',
                'eventCategory': 'Conversion: Campaign',
                'eventAction': 'click',
                'eventLabel': labelValue
            });
            var dataCampaignObj = {
                "Property": 'cookinburger',
                "Guid": localStorage.getItem("cookinburger_guid_raw")+'-'+rId,
                "Rid": rId,
                "Locale": window.pmApp.checkLocale(window.location.href),
                "Lp": window.location.href,
                "Reg": "0",
                "FT" : "0",
                "Conv": "1",
                "Username" : 'n/a',
                "Email": 'n/a',
                "Country": 'n/a',
                "City": 'n/a',
                "LonLat": 'n/a',
                "Ip": 'n/a',
                "Timezone": 'n/a',
                "Ref": localStorage.getItem("ref") !== null ? localStorage.getItem("ref") : 'n/a',
                "Lang": language
            };
            if (localStorage.getItem("game_conv_rid") === null) {
                window.pmApp.sendCampaignLog(dataCampaignObj);
            } else {
                var convDoneArray = localStorage.getItem("game_conv_rid");
                convDoneArray = convDoneArray.split(",");
                if (convDoneArray.indexOf(rId) === -1) {
                    window.pmApp.sendCampaignLog(dataCampaignObj);
                }
            }
        })
        .done(function() {
            return true;
        })
    } else {
        labelValue = 'organic | '+localStorage.getItem("cookinburger_guid_raw")+'-organic'+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
        dataLayer.push({
            'event' : 'gaEvent',
            'eventCategory': 'Conversion: Organic',
            'eventAction': 'click',
            'eventLabel': labelValue
        });
    }
  }
}

class NewsTemplate extends Component {
  state = {
    rid: false,
  };

  componentWillMount() {
    let params = queryString.parse(this.props.location.search)
    if (params.rid !== undefined) {
      this.setState({ rid: params.rid  });
    } else {
      if (typeof window !== 'undefined') {
        if (localStorage.getItem("rid") !== null) {
          this.setState({ rid: localStorage.getItem("rid")  });
        }
      }
    }
  }

  convertLink(htmlString, rid) {
    let returnString = htmlString;
    returnString = htmlString.replace(/href="https\:\/\/game-cookinburger\.playmining\.com/g,'href="https\:\/\/game-cookinburger\.playmining\.com/?rid='+rid);
    return returnString
  }

  convertLineBreak(htmlString) {
    let returnString = htmlString.replace(/\n/g,"<br />");
    return returnString;
  }

  render() {
    const { rid } = this.state;
    const newsArticle = this.props.data.contentfulPost;
    const {
      title,
      displayDate,
      createdAt,
      content,
    } = newsArticle;
    let articleString = rid !== false ? this.convertLink(content.childContentfulRichText.html, rid) : content.childContentfulRichText.html;
    articleString = this.convertLineBreak(articleString);

    const langDir = this.props.pageContext.langDir === 'ja' ? '/ja' : '/en';

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet
          title={title}
          meta={[
            { name: 'description', content: 'New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!' },
            { name: 'keywords', content: "Cookin' Burger, PlayMining, DEAPCoin, Blockchain Game" },
          ]}
        />
        <div className="l-main">

          <div className="section-inner_m">
              <div className="breadcrumb-list wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="0.7s" data-wow-offset="0">
                  <ul>
                      <li><Link to={`${langDir}`}>TOP</Link></li>
                      <li><Link to={`${langDir}/news`}>NEWS</Link></li>
                      <li>{title}</li>
                  </ul>
              </div>
          </div>

            <div className="l-under-section">
              <div className="section-inner_m">
                <div className="l-news-detail-section wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <div className="l-news-detail-section-day">{displayDate.split('T')[0].replace(/-/g,'.')}</div>
                  <h1 className="l-news-detail-section-title">{title}</h1>
                  <div className="l-news-detail-section-sm">
                    <div onClick={handleClick} dangerouslySetInnerHTML={{ __html: articleString }} />
                  </div>
                </div>
              </div>
            </div>

          <div className="section-inner_m">
            {/* <Link style={{color:'#4E2714', fontSize: 14, marginTop: 16, marginLeft: 8}} to="/">&#xab; Back to Home</Link> */}
          </div>
        </div>
      </Layout>
    )
  }
}

NewsTemplate.propTypes = propTypes

export default NewsTemplate

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulPost(id: { eq: $id }) {
      id
      title
      displayDate
      createdAt
      content {
        id
        childContentfulRichText {
            html
        }
      }
    }
  }
`
